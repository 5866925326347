function assertString(input) {
  if (typeof input !== "string") {
    throw new TypeError("Input must be a string");
  }
}

function merge(options, defaults) {
  return Object.assign({}, defaults, options);
}

function checkHost(host, list) {
  return list.includes(host);
}

function isIP(str, version) {
  if (version === 4) {
    const ipv4Pattern =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return ipv4Pattern.test(str);
  } else if (version === 6) {
    const ipv6Pattern = /(?:^|:)(?:[a-fA-F0-9]{0,4}){1,8}(?::(?:[a-fA-F0-9]{0,4}){1,8})*$/;
    return ipv6Pattern.test(str);
  }
  return false;
}

function isFQDN(str) {
  const fqdnPattern = /^(?!-)[a-zA-Z0-9-]{1,63}(?<!-)\.(?!-)[a-zA-Z]{2,}$/;
  return fqdnPattern.test(str);
}

const default_url_options = {
  protocols: ["http", "https", "ftp"],
  require_protocol: false,
  require_valid_protocol: true,
  allow_protocol_relative_urls: false,
  validate_length: true,
  require_host: true,
  require_port: false,
  allow_fragments: true,
  allow_query_components: true,
  disallow_auth: false,
  host_whitelist: null,
  host_blacklist: null,
};

function isURL(url, options) {
  assertString(url);
  if (!url || /[\s<>]/.test(url)) {
    return false;
  }
  if (url.indexOf("mailto:") === 0) {
    return false;
  }
  options = merge(options, default_url_options);
  if (options.validate_length && url.length >= 2083) {
    return false;
  }
  if (!options.allow_fragments && url.includes("#")) {
    return false;
  }
  if (!options.allow_query_components && (url.includes("?") || url.includes("&"))) {
    return false;
  }
  let protocol, auth, host, hostname, port, port_str, split, ipv6;
  split = url.split("#");
  url = split.shift();
  split = url.split("?");
  url = split.shift();
  split = url.split("://");
  if (split.length > 1) {
    protocol = split.shift().toLowerCase();
    if (options.require_valid_protocol && options.protocols.indexOf(protocol) === -1) {
      return false;
    }
  } else if (options.require_protocol) {
    return false;
  } else if (url.slice(0, 2) === "//") {
    if (!options.allow_protocol_relative_urls) {
      return false;
    }
    split[0] = url.slice(2);
  }
  url = split.join("://");
  if (url === "") {
    return false;
  }
  split = url.split("/");
  url = split.shift();
  if (url === "" && !options.require_host) {
    return true;
  }
  split = url.split("@");
  if (split.length > 1) {
    if (options.disallow_auth) {
      return false;
    }
    if (split[0] === "") {
      return false;
    }
    auth = split.shift();
    if (auth.indexOf(":") >= 0 && auth.split(":").length > 2) {
      return false;
    }
    const [user, password] = auth.split(":");
    if (user === "" && password === "") {
      return false;
    }
  }
  hostname = split.join("@");
  port_str = null;
  ipv6 = null;
  const wrapped_ipv6 = /^\[([^\]]+)](:[0-9]+)?$/;
  const ipv6_match = hostname.match(wrapped_ipv6);
  if (ipv6_match) {
    host = "";
    ipv6 = ipv6_match[1];
    port_str = ipv6_match[2] || null;
  } else {
    split = hostname.split(":");
    host = split.shift();
    if (split.length) {
      port_str = split.join(":");
    }
  }
  if (port_str !== null && port_str.length > 0) {
    port = parseInt(port_str, 10);
    if (!/^[0-9]+$/.test(port_str) || port <= 0 || port > 65535) {
      return false;
    }
  } else if (options.require_port) {
    return false;
  }
  if (options.host_whitelist && !checkHost(host, options.host_whitelist)) {
    return false;
  }
  if (host === "" && !options.require_host) {
    return true;
  }
  if (!isIP(host, 4) && !isIP(host, 6) && !isFQDN(host)) {
    return false;
  }
  host = host || ipv6;
  if (options.host_blacklist && checkHost(host, options.host_blacklist)) {
    return false;
  }
  return true;
}

export default isURL;
