import Vue from "vue";
import Router from "vue-router";
import { LoadingBar } from "view-design";
import store from "@/store";
import { isAuthenticated, hasSecurityPermissions, hasPermissionForHomeTab } from "./validations";
import { ABILITY_SUBJECTS } from "@/common/constant";

const Users = () => import(/* webpackPrefetch: true */ "@/views/Users");
const Reports = () => import(/* webpackPrefetch: true */ "@/views/Reports");
const NewReportsPage = () => import(/* webpackPrefetch: true */ "@/views/Reports.new.vue");
const PostCallSurveys = () => import(/* webpackPrefetch: true */ "@/components/AdminSetting/PostCallSurveys/PostCallSurveys");
const Report = () => import(/* webpackPrefetch: true */ "@/components/Reports/Report");
const Activity = () => import(/* webpackPrefetch: true */ "@/views/Activity");
const ActivityAGGrid = () => import(/* webpackPrefetch: true */ "@/views/ActivityAGGrid/ActivityAGGrid");
const Contacts = () => import(/* webpackPrefetch: true */ "@/views/Contacts");
const Support = () => import(/* webpackPrefetch: true */ "@/views/Support");
const Diagnostics = () => import(/* webpackPrefetch: true */ "@/views/Diagnostics");
const CaseManagement = () => import(/* webpackPrefetch: true */ "@/views/CaseManagement");
const SolutionList = () => import(/* webpackPrefetch: true */ "@/views/SolutionList");
const AudioCheck = () => import(/* webpackPrefetch: true */ "@/views/AudioCheck");
const Call = () => import(/* webpackPrefetch: true */ "@/views/Call");
const Settings = () => import(/* webpackPrefetch: true */ "@/views/Settings");
const Master = () => import(/* webpackPrefetch: true */ "@/views/master/Main");
const NewDashboard = () => import(/* webpackPrefetch: true */ "@/views/Dashboard.new.vue");
const QueueRebalancing = () => import(/* webpackPrefetch: true */ "@/views/Rebalancing.vue");
const NewLandingPage = () => import(/* webpackPrefetch: true */ "@/views/Landing.new.vue");
const CCPParser = () => import(/* webpackPrefetch: true */ "@/views/CCPParser.vue");
const CustomLinks = () => import(/* webpackPrefetch: true */ "@/views/CustomLinks.vue");

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Initial page",
      component: NewLandingPage,
      meta: {
        title: "Login",
        icon: "fa-home",
      },
    },
    {
      path: "/home",
      name: "master",
      redirect: hasPermissionForHomeTab() ? "/dashboard" : "/engage",
      component: Master,
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: NewDashboard,
          meta: {
            title: "Dashboard",
            icon: "fa-home",
            requiresAuth: true,
            securityProfile: [ABILITY_SUBJECTS.DASHBOARD_FLAG],
          },
        },
        {
          path: "/queues",
          name: "queues",
          component: QueueRebalancing,
          meta: {
            title: "Queues",
            icon: "fa-home",
            requiresAuth: true,
            securityProfile: [ABILITY_SUBJECTS.GENERAL],
          },
        },
        {
          path: "/parser",
          name: "CCPParser",
          component: CCPParser,
          meta: {
            title: "CCPParser",
            requiresAuth: true,
          },
        },
        {
          path: "/engage",
          name: "call",
          component: Call,
          meta: {
            title: "Engage",
            icon: "fa-headset",
            requiresAuth: true,
          },
        },
        {
          path: "/activity",
          name: "activity",
          component: Activity,
          meta: {
            title: "Activity",
            icon: "fa-clock",
            requiresAuth: true,
            securityProfile: [ABILITY_SUBJECTS.OLD_ACTIVITY_FLAG],
          },
        },
        {
          path: "/activitynew",
          name: "activitynew",
          component: ActivityAGGrid,
          meta: {
            title: "Activity",
            icon: "fa-clock",
            requiresAuth: true,
            securityProfile: [ABILITY_SUBJECTS.NEW_ACTIVITY_FLAG],
            secondaryPermission: [ABILITY_SUBJECTS.OWN_ACTIVITY, ABILITY_SUBJECTS.SAME_QUEUE, ABILITY_SUBJECTS.OTHERS_ACTIVITY],
          },
        },
        {
          path: "/contacts",
          name: "contacts",
          component: Contacts,
          meta: {
            title: "Contacts",
            icon: "fa-address-book",
            requiresAuth: true,
            securityProfile: [ABILITY_SUBJECTS.DIRECTORY],
          },
        },
        {
          path: "/support",
          name: "support",
          component: Support,
          meta: {
            title: "Support",
            icon: "fa-address-book",
            requiresAuth: true,
          },
        },
        {
          path: "/diagnostics",
          name: "diagnostics",
          component: Diagnostics,
          meta: {
            title: "Diagnostics",
            icon: "fa-address-book",
            requiresAuth: true,
          },
        },
        {
          path: "/case-management",
          name: "Case Management",
          component: CaseManagement,
          meta: {
            title: "Case Management",
            icon: "fa-address-book",
            requiresAuth: true,
            notAllowPartnerAccess: true,
          },
        },
        {
          path: "/solution-list",
          name: "Solution List",
          component: SolutionList,
          meta: {
            title: "Solution List",
            icon: "fa-address-book",
            requiresAuth: true,
          },
        },
        {
          path: "/audio-check",
          name: "Audio Check",
          component: AudioCheck,
          meta: {
            title: "Audio Check",
            icon: "fa-address-book",
            requiresAuth: true,
          },
        },
        {
          path: "/reports",
          name: "reports",
          component: Reports,
          meta: {
            title: "Reports",
            icon: "fa-chart-area",
            requiresAuth: true,
            securityProfile: [ABILITY_SUBJECTS.REPORTING],
          },
          children: [
            {
              path: "/report/:id",
              name: "report",
              component: Report,
            },
          ],
        },
        {
          path: "/reportsnew",
          name: "reportsnew",
          component: NewReportsPage,
          meta: {
            title: "Reportsnew",
            icon: "fa-chart-area",
            requiresAuth: true,
            securityProfile: [ABILITY_SUBJECTS.REPORTING],
          },
          children: [
            {
              path: "/reportnew/:id",
              name: "reportnew",
              component: Report,
            },
          ],
        },
        {
          path: "/users",
          name: "users",
          component: Users,
          meta: {
            title: "Users",
            icon: "fa-users",
            requiresAuth: true,
            securityProfile: [ABILITY_SUBJECTS.USERS],
          },
        },
        {
          path: "/settings",
          name: "settings",
          component: Settings,
          meta: {
            title: "Settings",
            icon: "fa-cogs",
            requiresAuth: true,
          },
          children: [
            {
              path: "/settings/surveys",
              name: "post_call_surveys",
              component: PostCallSurveys,
              meta: {
                title: "Surveys",
                requiresAuth: true,
              },
            },
          ],
        },
        {
          path: "/customLinks",
          name: "custom-links",
          component: CustomLinks,
          meta: {
            title: "Settings",
            icon: "fa-link",
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: "/:subdomain",
      name: "login template sub",
      beforeEnter(to, from, next) {
        if (store.getters["authentication/isAutenticatedInAmplify"]) {
          if (!hasPermissionForHomeTab()) {
            next({ path: "/engage" });
            return false;
          } else {
            next({
              path: "/home",
              replace: true,
            });
          }
        } else {
          next();
        }
      },
      component: NewLandingPage,
      meta: {
        title: "Login",
        icon: "fa-home",
      },
    },
  ],
});

LoadingBar.config({
  height: 4,
});

router.beforeResolve(async (to, from, next) => {
  if (!(await isAuthenticated(to))) {
    next({ path: "/" });
    return false;
  }
  if (!(await hasSecurityPermissions(to))) {
    if (!hasPermissionForHomeTab()) {
      next({ path: "/engage" });
      return false;
    }
    next({ path: "/home" });
    return false;
  }
  next();
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title + " - ConnectPath";
  LoadingBar.start();
  next();
});

router.afterEach(() => {
  LoadingBar.finish();
});

export default router;
