import Vue from "vue";
import App from "./App.vue";
import "simplebar";
import "simplebar/dist/simplebar.css";
import * as sMSDK from "aws-sdk";
import "amazon-connect-streams/release/connect-streams-dr.js";
import "amazon-connect-streams";
import "amazon-connect-chatjs";
import "amazon-connect-taskjs";
import "./libs/connect-rtc.js";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AGGRID_LINCESE } from "@connectpath/common-frontend";
import { LicenseManager } from "ag-grid-enterprise";
import VueDragResize from "vue-drag-resize";
// console.log("#HAR TEST : ", VueDraggableResizable);

if (process.env.NODE_ENV === "production") {
  LicenseManager.setLicenseKey(AGGRID_LINCESE);
}

window.sMSDK = sMSDK;

import ExtensionService from "./common/services/extension.service";
ExtensionService();

import router from "./router";

import store from "./store";
import VueWait from "vue-wait";

// import plugins
import { i18n } from "./plugins/language";
import "./plugins/vue-cookie";
import "./plugins/view-design";
import Notify from "./plugins/notify";
Vue.use(Notify);

Vue.use(VueWait);

Vue.config.productionTip = process.env.NODE_ENV !== "production";
Vue.config.devtools = process.env.NODE_ENV !== "production";
Vue.config.performance = process.env.NODE_ENV !== "production";

// custom filters
import DateFilter from "@/common/filters/date.filter";
import DateTimeFilter from "@/common/filters/dateTimeFormatted.filter";
import ZonedDateFilter from "@/common/filters/dateWithZone.filter";
import DateShortFilter from "@/common/filters/dateShort.filter";
import TimeFilter from "@/common/filters/time.filter";
import StatusFilter from "@/common/filters/status.filter";
import DurationFilter from "@/common/filters/duration.filter";
import DurationSecondsFilter from "@/common/filters/duration.seconds.filter";
import DurationFilterStr from "@/common/filters/durationStr.filter";
import HighlightFilter from "@/common/filters/highlight.filter";
import durationLiveLook from "@/common/filters/durationLiveLook.filter";
import PhoneNumberFilter from "@/common/filters/phoneNumber.filter";
import RelativeDateTimeFilter from "@/common/filters/relativeDateTime.filter";
import nameOnlyForSAML from "@/common/filters/nameOnlyForSAML.filter";

Vue.filter("date", DateFilter);
Vue.filter("dateTime", DateTimeFilter);
Vue.filter("zonedDate", ZonedDateFilter);
Vue.filter("dateShort", DateShortFilter);
Vue.filter("highlight", HighlightFilter);
Vue.filter("durationSeconds", DurationSecondsFilter);
Vue.filter("status", StatusFilter);
Vue.filter("duration", DurationFilter);
Vue.filter("durationStr", DurationFilterStr);
Vue.filter("durationLiveLook", durationLiveLook);
Vue.filter("time", TimeFilter);
Vue.filter("phoneNumber", PhoneNumberFilter);
Vue.filter("relativeDateTime", RelativeDateTimeFilter);
Vue.filter("usernameDisplay", nameOnlyForSAML);

import "@/common/directives.js";

// for the UI

const PageWrapper = () => import("@/components/PageWrapper");
const EmptyContent = () => import("@/components/EmptyContent");
const DatePickerRange = () => import("@/components/DatePickerRange");
const UserAvatar = () => import("@/components/UserAvatar");
const QueueRenderer = () => import("@/components/QueueRenderer");

Vue.component("PageWrapper", PageWrapper);
Vue.component("EmptyContent", EmptyContent);
Vue.component("DatePickerRange", DatePickerRange);
Vue.component("UserAvatar", UserAvatar);
Vue.component("QueueRenderer", QueueRenderer);
Vue.component("vue-drag-resize", VueDragResize);
/*
/  you may think that this code block is obsolete
/  and doesn't seem to do anything. and you would be
/  correct. but when we remove this for some reason
/  the whole app crashes and we can't figure out why
/  so here it will stay
*/

window.globalWindow = null;
window.Broadcast = new Vue({});
window.intervalUpdateUser = null;

// temporary to remove connect-rtc from certain instances
window.disableConnectRTCInstances = [];

window.STATUS_IDENTIFIER = (state, type) => {
  switch (state) {
    case "PendingBusy":
      return type == "bg" ? "is-bg-status-incoming-call" : "is-status-incoming-call";
    case "Busy":
      return type == "bg" ? "is-bg-status-busy" : "is-status-busy";
    case "CallingCustomer":
      return type == "bg" ? "is-bg-status-incoming-call" : "is-status-incoming-call";
    case "MissedCallAgent":
      return type == "bg" ? "is-bg-status-missed-forced" : "is-status-missed-forced";
    case "Hold":
      return type == "bg" ? "is-bg-status-other" : "is-status-other";
    case "AfterContactWork":
      return type == "bg" ? "is-bg-status-wrap-up" : "is-status-wrap-up";
    case "AfterCallWork":
      return type == "bg" ? "is-bg-status-wrap-up" : "is-status-wrap-up";
    case "Available":
      return type == "bg" ? "is-bg-status-ready" : "is-status-ready";
    case "Offline":
      return type == "bg" ? "is-bg-status-offline" : "is-status-offline";
    default:
      return type == "bg" ? "is-bg-status-other" : "is-status-other";
  }
};

window.validateEmail = function (email) {
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

window.validateUrl = function (value) {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    value
  );
};

window.calcTime = function (offset, mm) {
  var d = new Date(mm);
  var tzDifference = offset * 60 + d.getTimezoneOffset();
  var offsetTime = new Date(d.getTime() + tzDifference * 60 * 1000);
  return offsetTime.getTime();
};

window.onresize = function () {
  store.commit("setWindowSize", window, { root: 1 });
};

window.mPeerInitiator = null;
window.mPeers = null;
window.mediaTech = {
  audioElement: null,
  stream: null,
  audio: null,
};
// Vue.config.devtools = true;
/* eslint-disable no-new */

let vue = new Vue({
  i18n,
  router,
  store,
  mounted() {
    store.commit("setWindowSize", window, { root: 1 });
    store.dispatch("detectWebRTC", null, { root: 1 });
  },
  render: (h) => h(App),
  wait: new VueWait({
    useVuex: true,
  }),
}).$mount("#app");

window.dextr = process.env.NODE_ENV === "production" ? null : vue;
